import { Injectable, HostListener } from '@angular/core';
import { PreviewModel } from '../models/preview.model';
import { Subject, Observable,BehaviorSubject } from 'rxjs';
import { Constants } from '@shared/services/constants';
import { Router } from '@angular/router';
import { WindowRefService } from '@core/window-ref.service';
import { ToasterService } from '@core/toaster.service';
import { CookieService } from '@core/cookie.service';
import { DataService } from './now-data.service';
import { environment } from '@env/environment';
import { Angulartics2Segment } from 'angulartics2/segment';
import { AuthService } from '@core/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root'
})
export class DataCommService {
    isFeatureUserUploaded = false;
    featureListSubject: Subject<PreviewModel[]> = new Subject();
    featureList$ = this.featureListSubject.asObservable();
    dataErrorSubject: Subject<boolean> = new Subject();
    dataError$ = this.dataErrorSubject.asObservable();
    selectedAreaSubject: Subject<any> = new Subject();
    selectedArea$ = this.selectedAreaSubject.asObservable();
    displayMoreItemsSubject: Subject<any> = new Subject();
    displayMoreItems$ = this.displayMoreItemsSubject.asObservable();
    selectedHotspotSubject: Subject<any> = new Subject();
    selectedHotspot$ = this.selectedHotspotSubject.asObservable();
    private isSideKickAddedSource = new Subject<object>();
    isSidePanelClicked$ = this.isSideKickAddedSource.asObservable();
    fileCountSource = new Subject();
    fileCountSource$ = this.fileCountSource.asObservable();
    addIconsSource = new Subject();
    addIconsSource$ = this.addIconsSource.asObservable();
    iconListSource = new Subject();
    iconListSource$ = this.iconListSource.asObservable();
    addClickableItemSource = new Subject();
    addClickableItemSource$ = this.addClickableItemSource.asObservable();
    switchViewSource = new Subject();
    switchViewSource$ = this.switchViewSource.asObservable();
    isMobileDeviceSource: Subject<boolean> = new Subject();
    isMobileDevice$ = this.isMobileDeviceSource.asObservable();
    touchGestureSource: Subject<string> = new Subject();
    touchGesture$ = this.touchGestureSource.asObservable();
    mobOtherOptionsSource: Subject<string> = new Subject();
    mobOtherOptions$ = this.mobOtherOptionsSource.asObservable();
    industryTypeSource = new Subject();
    industryTypeSource$ = this.industryTypeSource.asObservable();

    sidekickImageClickSource: Subject<any> = new Subject();
    sidekickImageClicked$ = this.sidekickImageClickSource.asObservable();

    setSuggestionTootipPosSource: Subject<any> = new Subject();
    setSuggestionTootipPos$ = this.setSuggestionTootipPosSource.asObservable();

    suggestionTooltipClickedSource: Subject<any> = new Subject();
    suggestionTooltipClicked$ = this.suggestionTooltipClickedSource.asObservable();

    rejectSidekickRemovalSource: Subject<any> = new Subject();
    rejectSidekickRemoval$ = this.rejectSidekickRemovalSource.asObservable();

    cpeSavedSource: Subject<any> = new Subject();
    cpeSaved$ = this.cpeSavedSource.asObservable();

    syncDataSource: Subject<any> = new Subject();
    syncData$ = this.syncDataSource.asObservable();

    syncOverlaySource: Subject<any> = new Subject();
    syncOverlay$ = this.syncOverlaySource.asObservable();

    prototypeListSource: Subject<any> = new Subject();
    prototypeList$ = this.prototypeListSource.asObservable();

    brandingForm: BehaviorSubject<string> = new BehaviorSubject("");

    logoSource = new Subject();
    logoSource$ = this.logoSource.asObservable();
    logoUpdateSource = new Subject();
    logoUpdateSource$ = this.logoUpdateSource.asObservable();
    hotspotTooltip = new Subject();

    industries;
    defaultIndustry;
    nowBuildCardId: string;
    featureList: PreviewModel[] = [];
    buildcardId: string;
    featureUniqCode = '';
    projectName = '';
    projectOwnerName = '';
    featureCount = 0;
    dataError = false;
    errorMsg = '';
    currentFrameIndex: number;
    landScapeMode = false;
    spotTypeForDestination: string;
    masterFeatureList: PreviewModel[];
    appReloaded = true;
    showShareModal = false;
    sharingUrl = '';
    public isContentChanged = false;
    public openConfirmBox = false;
    public confirmDeleteScreen = false;
    Arr = Array;
    uploadedFilesCount = 0;
    public featureId: any;
    iconsList = [];
    public dashboardLoader = false;
    public platform = 'mobile';
    public isMobileDevice: boolean;
    dashboardUrl = 'flowchart';
    heroImageId: string;
    prototypePaid = false;
    customPrototypePaid = false;
    buildcardPaid = false;
    isOwner = false;
    cpeSaveDisabled = false;
    designsApproved = false;
    prototypeType = '';
    isWebReviewed = false;
    isMobileReviewed = false;
    showReviewedAllPopup = false;
    synOverlay = false;
    public isCpe = false;
    public updateInfo = true;
    public rippleEffect = false;
    public showTemplateBtn = false;
    showLogoPopup = false;
    clientVerion = 0;
    public guidline = {
        viewAllHotspot: true,
        runPrototype: false,
        customisePrototype: false,
        sharePrototype: false,
        downloadPrototype: false,
        createHotspotArea: true,
        editHotspotArea: true,
        addNewScreen: false,
        showHideConnector: false,
        viewUnlinkedScreen: false,
        selectDestination: true,
        selectTransition: false,
        launchScreen: true,
        moreOptions: false,
        selectActionTypes: false
    };
    public featureListMobile: PreviewModel[] = [];
    public betaPasswordChecked = false;
    public isPasswordProtected = false;
    public isPasswordProtectedBoxShown = false;
    showDownloader = false;
    downloadFormat;
    public showHideSuggToolTipOnEditFeature = false;
    public showHideContxtMenuOnEditFeature = false;
    childSwipeTriggered: boolean;
    actionTypeList: any;
    showCustomPrototypePopup = false;
    applicationId;
    isMasterTemplate: boolean;
    showHeroActionMenu = false;
    actionMenuDataObj;
    actionMenuPosition;
    checkMirrorOptions: boolean;
    featureTitleUpdated = false;
    rejectionCommentDetails = {
        show: false,
        comment: '',
        featureId: '',
        position: {}
    };
    customPrototypePrice: string;
    customPrototypeSysmbol: string;
    prototypeList = [
        'Instant',
        'Custom'
    ];
    prototypeSelected = '';
    cmsUpdated = 0;
    userUpdated = 0;
    launchScreenObj: any;
    buildCardUniqueCode: string;
    isEditable: boolean;
    public featureVersion;
    public brandLogo: string;
    public showAllFeaturesWindow = false;
    addedScreensCount = new Subject();
    addedScreensCount$ = this.addedScreensCount.asObservable();
    public allFeatures: any;
    showUnlinkSlider = false;
    grayStyle = false;
    myMapping = [
        {
            isExist: true,
            platformType: 'mobile',
            comingSoon: false,
            selected: true,
            value: [
                { device: 'Android', comingSoon: false , selected: true, cpe: true},
                { device: 'IOS', comingSoon: false, selected: false, cpe: false},
                { device: 'Mobile Site', comingSoon: false, selected: false , cpe: false},
                // { device: 'watchOS', comingSoon: true, selected: false, cpe: false},
                // { device: 'Oculus', comingSoon: false , selected: false, cpe: false},

            ]
        },
        {
            isExist: true,
            platformType: 'web',
            comingSoon: false,
            selected: false,
            value: [
                // { device: 'Mobile Site', comingSoon: false, selected: false , cpe: false},
                { device: 'Web', comingSoon: false , selected: false, cpe: true},
                { device: 'Windows', comingSoon: false, selected: false, cpe: false },
                { device: 'macOS', comingSoon: false, selected: false, cpe: false },
                // { device: 'Windows Phone', comingSoon: true, selected: false, cpe: false }
            ]
        },
        {
            isExist: true,
            platformType: 'tablet',
            comingSoon: true,
            selected: false,
            value: [
                { device: 'Android', comingSoon: true , selected: false},
                { device: 'IOS', comingSoon: true , selected: false},
                { device: 'Windows Phone', comingSoon: true , selected: false }
            ]

        },
        {
            isExist: true,
            platformType: 'watch',
            comingSoon: false,
            selected: false,
            value: [
                { device: 'watchOS', comingSoon: false }
            ]

        }
    ]
    allPlatforms: any = [];
    selectedPlatforms: any = [];
    headerloop=[];
    currentPlatform:string;
    public userDevice: string;
    public userDeviceOS: string;
    public userBrowser: string;
    public userUniqueid: string;
    allFeaturesArray: PreviewModel[] = [];
    navHotspotsRequired: boolean = true;

    constructor(
        private dataService: DataService,
        private windowRef: WindowRefService,
        private cookieService: CookieService,
        private toasterService: ToasterService,
        private router: Router, private authService: AuthService,
        private analyticsSegment: Angulartics2Segment,
        private deviceService: DeviceDetectorService) {
        this.featureList = [];
        this.featureUniqCode = null;
        let isLandScape;
        windowRef.nativeWindow.addEventListener('orientationchange', (elem: any) => {
            if (windowRef.nativeWindow.orientation === -90 || windowRef.nativeWindow.orientation === 90) {
                isLandScape = true;
            } else {
                isLandScape = false;
            }
            if (elem.target.innerWidth >= 600 && isLandScape) {
                this.landScapeMode = false;
            } else {
                if (isLandScape) {
                    this.landScapeMode = true;
                } else {
                    this.landScapeMode = false;
                }
            }
        }, false);

        windowRef.nativeWindow.addEventListener('resize', (elem: any) => {
            const isMobile = this.checkIsMobileDevice();
            this.emitMobileDevice(isMobile);
        });
        const currentUrl = this.windowRef.nativeWindow.location.href;
        this.buildcardId = currentUrl.split('/')[3];
        this.sharingUrl = currentUrl.split('/').splice(-0, 4).join('/') + `${currentUrl.includes('v1') ? '/v1' : ''}/preview`;
        this.setDisplayMoreItemsSubject(false);
        // get guidline cookie from users browser to hide tooltip
        if (this.cookieService.getLocalStorage(environment.GUIDLINE_TOOTIP_COOKIE)) {
            this.guidline = JSON.parse(this.cookieService.getLocalStorage(environment.GUIDLINE_TOOTIP_COOKIE));
        }
        this.getUserBrowserDevice();
    }

    getProjectName(): Observable<string> {
        return this.brandingForm.asObservable();
    }

    setProjectName(projectName: string) {
        this.brandingForm.next(projectName);
    }

    reloadData() {
        const urlParams = this.router.url.split(';');
        this.platform = urlParams[urlParams.length - 1].split('=')[1];
        this.switchView(this.platform);
        this.dataService.getDataByBuildcardId(this.buildcardId, this.platform)
            .subscribe((data: any) => {
                if (this.redirectFromDashboard()) {
                    return false;
                }
            }, (error) => {
                this.errorHandlr(error);
            });
    }

    sidekickImageClicked(data: any) {
        this.sidekickImageClickSource.next(data);
    }
    suggestionTooltipClicked(data: any) {
        this.suggestionTooltipClickedSource.next(data);
    }

    rejectSidekickRemoval(data: any) {
        this.rejectSidekickRemovalSource.next(data);
    }

    cpeSavedFun(isRefreshed) {
        this.cpeSavedSource.next(isRefreshed);
    }

    emitMobileDevice(value: boolean) {
        if (this.isMobileDevice !== value) {
            this.isMobileDevice = value;
            this.isMobileDeviceSource.next(value);
        }
    }

    setUploadedFilesCount(fileCount: any) {
        this.fileCountSource.next(fileCount);
    }

    setAddedScreensCount(screenCount: any) {
        this.addedScreensCount.next(screenCount);
    }

    setFeatureList(data: any) {
        let defaultFound = false;
        if (data.features.length) {
            data.features.forEach((key, value) => {
                if (!defaultFound && data.features[value].is_launch_screen && data.features[value].is_main) {
                    defaultFound = true;
                } else {
                    data.features[value].is_launch_screen = false;
                }
            });
        }
        this.featureList = data.features.sort((a, b) => {
            if (a.is_launch_screen && a.is_main) {
                return -1;
            } else {
                return 0;
            }
        });
        this.featureList = [...data.features];
        this.featureList = this.featureList.filter(f => f.story_type !== 'sub_task');
        this.featureListSubject.next(data);
        this.setPreviewFrameIndex();
        this.getMaxFeatureVersion();
    }

    setDataError(isError: boolean) {
        this.dataError = isError;
        this.dataErrorSubject.next(isError);
    }

    successHandlr(data) {
        this.navHotspotsRequired = data.nav_hotspots_required;
        this.isOwner =  data.is_owner;
        this.prototypePaid = data.prototype_payment_status;
        this.customPrototypePaid = data.custom_prototype_payment_status;
        this.buildcardPaid = data.is_running_card;
        this.buildCardUniqueCode = data.uniq_code;
        this.brandLogo = data.brand_logo;
        this.isEditable = (data.is_editable === undefined) ? true : data.is_editable;
        this.clientVerion = data.client_version;
        if (!this.applicationId) {
            this.applicationId = data.application_id;
        }
        this.isMasterTemplate = data.is_master_template;
        if (!this.isOwner) {
            this.disableCoachmarkForLoggedOutUser();
        }
        if (data.build_card_id) {
            this.nowBuildCardId = data.build_card_id;
        }

        if (data && data.features && data.features.length > 0) {
            this.setFeatureList(data);
            this.projectName = data.project_name;
            this.projectOwnerName = data.owner_name;
            this.isPasswordProtected = data.password_protected;
            this.featureCount = data.features_count;
        } else if (data && data.features && data.features.length === 0) {
            this.setFeatureList(data);
            this.errorMsg = Constants.noFeatureErrorMsg;
            this.projectName = data.project_name;
            this.projectOwnerName = data.owner_name;
            this.isPasswordProtected = data.password_protected;
            this.featureCount = data.features_count;
        }
        this.cpeSavedFun(true);
        this.isWebReviewed = !data.under_client_review_web;
        this.isMobileReviewed = !data.under_client_review_mobile;
        this.prototypeType = data.prototype_type;
        if (data.prototype_list && data.prototype_list.length > 0) {
            this.prototypeSelected = data.prototype_type;
        }
        this.designsApproved = data.prototype_approved;
        if (!this.buildcardPaid && !this.isUiWorkspace()) {
            this.customPrototypeAmount();
        }
    }
    isUiWorkspace() {
        return this.router.url.includes('uiworkspace');
    }


    errorHandlr(err) {
        this.setDataError(true);
        if (err && err.error) {
            this.errorMsg = err.error.message;
        } else {
            this.toasterService.error(Constants.toasterMessage.ERROR);
        }
    }

    setPreviewFrameIndex(index?: number) {
        if (index) {
            this.currentFrameIndex = index;
        }
    }
    setFrameUrl(comp: string, urlSplitArray): string {
        const currentUrlArray = JSON.parse(JSON.stringify(urlSplitArray));
        if (currentUrlArray.indexOf(comp) === -1) {
            currentUrlArray.push(comp);
        }
        currentUrlArray.push(this.featureUniqCode);
        return currentUrlArray.join('/');
    }

    setSelectedHotspot(hotspot: any) {
        this.spotTypeForDestination = hotspot.category;
        this.selectedHotspotSubject.next(hotspot);
    }

    getVisibleHotspotsCounts(hotspots: any[]) {
        let count = 0;
        if (hotspots && hotspots.length > 0) {
            hotspots.forEach(hotspot => {
                const items = hotspot.clickable_items;
                if (items && items.length > 0) {
                    count += items.length;
                }
            });
        }
        return count;
    }

    setSelectedArea(area: any) {
        this.selectedAreaSubject.next(area);
    }

    setDisplayMoreItemsSubject(value: boolean) {
        this.displayMoreItemsSubject.next(value);
    }

    sideKickAddFunc(customHotSpots) {
        this.isSideKickAddedSource.next(customHotSpots);
    }

    addIconFun(iconData) {
        this.addIconsSource.next(iconData);
    }

    updateIconList(iconList) {
        this.iconListSource.next(iconList);
        this.iconsList = iconList;
    }

    addClickableItemFun(clickbaleItem) {
        this.addClickableItemSource.next(clickbaleItem);
    }

    setSuggestionTootipPosFun(featureId) {
        this.setSuggestionTootipPosSource.next(featureId);
    }

    redirectFromDashboard(): boolean {
        let redirected = false;
        if (!this.isOwner && !this.authService.userProfile.isDesigner) {
            redirected = true;
        } 
        else if (!this.prototypePaid && !this.buildcardPaid) {
                this.windowRef.nativeWindow.location = this.dataService.getBuilderPaymentUrl(this.buildcardId);
                redirected = true;
        } 
        else {
                if (this.cookieService.getCookie('buildernow_visited') === null && !this.checkIsMobileDevice()) {
                    if (this.authService.cutsotmPrototypeVersion) {
                        this.cookieService.setCookie('buildernow_visited', 'true');
                        redirected = false;
                    } else {
                        this.router.navigateByUrl(this.buildcardId + '/welcome');
                    }
                }
        }
        return redirected;
    }

    redirectFromPreview(): boolean {
        let redirected = false;
        if (this.isOwner) {
            if (!this.prototypePaid && !this.buildcardPaid) {
                this.windowRef.nativeWindow.location = this.dataService.getBuilderPaymentUrl(this.buildcardId);
                redirected = true;
            } else if (this.cookieService.getCookie('buildernow_visited') === null && !this.checkIsMobileDevice()) {
                if (this.authService.cutsotmPrototypeVersion) {
                    this.cookieService.setCookie('buildernow_visited', 'true');
                    redirected = false;
                } else {
                    this.router.navigateByUrl(this.buildcardId + '/welcome');
                }
            }
        }
        return redirected;
    }

    switchView(value) {
        this.platform = value;
        this.switchViewSource.next(value);
    }

    checkIsMobileDevice(): boolean {
        return this.windowRef.nativeWindow.innerWidth <= 480;
    }

    mobOtherOptions(value: string) {
        this.mobOtherOptionsSource.next(value);
    }

    touchGesture(type: string) {
        this.touchGestureSource.next(type);
    }

    setGuidLineCookie(prevKey, nexKey) {
        this.guidline[prevKey] = false;
        this.guidline[nexKey] = true;
        this.setTooltipMessage(prevKey);
        this.cookieService.setLocalStorage(environment.GUIDLINE_TOOTIP_COOKIE, JSON.stringify(this.guidline));
    }

    disableCoachmarkForLoggedOutUser() {
        for (const guideLikeVal of Object.keys(this.guidline)) {
            this.guidline[guideLikeVal] = false;
        }
    }

    closeGuideLine(...args) {
        args.forEach(guidelinParams => {
            this.guidline[guidelinParams] = false;
            this.setTooltipMessage(guidelinParams);
        });
        this.cookieService.setLocalStorage(environment.GUIDLINE_TOOTIP_COOKIE, JSON.stringify(this.guidline));
    }
    setTooltipMessage(val) {
        let toolTipVar = '';
        switch (val) {
            case 'viewAllHotspot':
                toolTipVar = 'BN_Reveal Hotspot Tooltip viewed';
                break;
            case 'runPrototype':
                toolTipVar = 'BN_Run Prototype Tooltip viewed';
                break;
            case 'customisePrototype':
                toolTipVar = 'BN_User Flow Mode Tooltip viewed';
                break;
            case 'sharePrototype':
                toolTipVar = 'BN_Share Prototype Tooltip viewed';
                break;
            case 'downloadPrototype':
                toolTipVar = 'BN_Download Prototype Tooltip viewed';
                break;
            case 'launchScreen':
                toolTipVar = 'BN_Launch screen tooltip viewed';
                break;
            case 'moreOptions':
                toolTipVar = 'BN_More options tooltip viewed';
                break;
            case 'addNewScreen':
                toolTipVar = 'BN_Add new screen tooltip viewed';
                break;
            case 'showHideConnector':
                toolTipVar = 'BN_Hide/Show connector tooltip viewed';
                break;
            case 'viewUnlinkedScreen':
                toolTipVar = 'BN_Unlinked screens tooltip viewed';
                break;
            case 'selectDestination':
                toolTipVar = 'BN_Add destination tooltip viewed';
                break;
            case 'selectTransition':
                toolTipVar = 'BN_Add transition tooltip viewed';
                break;
            case 'selectActionTypes':
                toolTipVar = 'BN_Add action tooltip viewed';
                break;
            case 'createHotspotArea':
                toolTipVar = 'BN_Add Hotspot Tooltip viewed';
                break;
            case 'editHotspotArea':
                toolTipVar = 'BN_Edit hostpot tooltip viewed';
                break;
        }
        if (toolTipVar) {
            this.trackEvent(toolTipVar, '');
        }
    }

    closeGuideLineAfterFiveSec() {
        setTimeout(() => {
            this.disableCoachmarkForLoggedOutUser();
        }, 5000);
    }

    industryTypeClicked() {
        this.industryTypeSource.next(true);
    }

    trackEvent(title: string, value?: any) {
        if (environment?.production) {
            const userCookieData = this.cookieService.getCookie(this.cookieService.userCookie);
            let userData;
            if (userCookieData) {
                //  this.cookieService.setAllCookie();
                userData = JSON.parse(userCookieData[1]);
            }
            let obj;
            const defaultParam = {
                'Build Card paid': (this.buildcardPaid) ? 'Y' : 'N',
                Mode: this.platform,
                User: (userData) ? 'Logged in' : 'Not logged in'
            };
            if (this.windowRef.nativeWindow.location.href.indexOf('flowchart') !== -1) {
                obj = { ...defaultParam, ...{ Dashboard: 'Flowchart' } };
            } else if (this.windowRef.nativeWindow.location.href.indexOf('gridview') !== -1) {
                obj = { ...defaultParam, ...{ Dashboard: 'Grid' } };
            } else {
                obj = defaultParam;
            }
            if (value) {
                obj = { ...obj, ...value };
            }
            this.analyticsSegment.eventTrack(title, obj);
        }
    }

    trackSaveEvents(eventName) {
        const source = this.sourcePage();
        this.trackEvent(eventName, { Source: source });
    }
    sourcePage() {
        let source = '';
        if (this.router.url.includes('edit-screen')) {
            source = 'Edit hotspot';
        } else if (this.router.url.includes('add-hotspot')) {
            source = 'Add hotspot';
        } else if (this.router.url.includes('add-sidekick')) {
            source = 'Add sidekick';
        } else if (this.router.url.includes('add-icon')) {
            source = 'Add icon';
        }
        return source;
    }

    syncDataFun(param) {
        this.syncDataSource.next(param);
    }

    syncOverlayFun(flag) {
        this.syncOverlaySource.next(flag);
    }

    prototypeListFun(data) {
        this.prototypeListSource.next(data);
    }

    syncData(frameData) {
        frameData.forEach((elem, key) => {
            if (elem.revised_on_cms === true) {
                this.cmsUpdated++;
            }
            if (elem.user_modified === true) {
                this.userUpdated++;
            }
        });
        if (this.cmsUpdated > 0) {
            this.synOverlay = true;
            const tcmsUpdated = this.cmsUpdated;
            const tuserUpdated = this.userUpdated;
            this.cmsUpdated = 0;
            this.userUpdated = 0;
            this.syncDataFun({ cmsUpdated: tcmsUpdated, userUpdated: tuserUpdated });
        }
    }

    getRejCommBoxPosition(event: any) {
        let left;
        let top;
        try {
            if (event.target.closest('.prototype-details').getBoundingClientRect().left >= 0) {
                left = Math.round(event.target.closest('.prototype-details').getBoundingClientRect().left);
            } else {
                left = event.screenX;
            }
            if (event.target.closest('.prototype-details').getBoundingClientRect().top >= 0) {
                top = Math.round(event.target.closest('.prototype-details').getBoundingClientRect().top);
            } else {
                top = event.screenY;
            }
        } catch (err) {
            left = event.screenX;
            top = event.screenY;
        }
        return {
            top: (top - 80),
            left: (left - 80)
        };
    }

    getMaxFeatureVersion() {
        this.featureVersion = 0;
        this.featureList.forEach(feature => {
            if (feature.version > this.featureVersion) {
                this.featureVersion = feature.version;
            }
        });
    }

    customPrototypeAmount() {
        if (!this.buildcardPaid) {
            this.dataService.customPrototypeAmount(this.buildcardId).subscribe((res: any) => {
                if (res.data.attributes.installment_amount) {
                    this.customPrototypeSysmbol = res.data.attributes.currency.data.attributes.symbol;
                    this.customPrototypePrice = res.data.attributes.installment_amount + res.data.attributes.tax_amount;
                }
            });
        }
    }

    logoSourceFun(data) {
        this.logoSource.next(data);
    }
    logoUpdateFun(data) {
        this.logoUpdateSource.next(data);
    }
    saveUnlinkedFeatureInLocalStorage(unlinkFeatureList) {
        const getUnlinkFeatureData = JSON.parse(this.cookieService.getLocalStorage(Constants.rearrangeLocalStorageKeyName));
        if (getUnlinkFeatureData) {
            const mergeArray = [...unlinkFeatureList, ...getUnlinkFeatureData];
            this.cookieService.setLocalStorage(Constants.rearrangeLocalStorageKeyName, JSON.stringify([...new Set([...mergeArray])]));
        } else {
            this.cookieService.setLocalStorage(Constants.rearrangeLocalStorageKeyName, JSON.stringify(unlinkFeatureList));
        }
    }

    checkUnlinkFeatureIdInLocalStorage(unlinkFeatureList) {
        const getUnlinkFeatureData = JSON.parse(this.cookieService.getLocalStorage(Constants.rearrangeLocalStorageKeyName));
        if (getUnlinkFeatureData) {
            unlinkFeatureList.forEach((item) => {
                const isFeatureIdExist = getUnlinkFeatureData.findIndex(arr => arr === item);
                if (isFeatureIdExist === -1) {
                    return true;
                } else {
                    return false;
                }
            });
        } else {
            return true;
        }
    }
    getDashboardFeatureList(nowBuildCardId) {
        if (nowBuildCardId) {
            this.dataService.getDashboardFeatureLists(nowBuildCardId, this.platform).subscribe((res: any) => {
                this.allFeatures = res;
            }, (err) => {
                this.toasterService.error('Something Went Wrong!');
            });
        }
    }

    isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    createMapping() {
        const headerLoop = [];
        let innerMapping: any = {title: '', values: []};
        const selectedPlatforms = this.selectedPlatforms;
        const mappedPlatforms = this.myMapping;
        mappedPlatforms.forEach(mappedPlatform => {
            if (mappedPlatform.isExist) {
                const values = mappedPlatform.value;
                values.forEach(devices => {
                    const device = selectedPlatforms.find(e => e.title == devices.device);
                    const duplicatedDevice = headerLoop.find(e => e.title == mappedPlatform.platformType);
                    if (device && !duplicatedDevice) {
                        innerMapping.title = mappedPlatform.platformType;
                        innerMapping.comingSoon = mappedPlatform.comingSoon;
                        innerMapping.selected = mappedPlatform.selected;
                        const object = Object.assign({}, device);
                        object.selected = false;
                        object.comingSoon = devices.comingSoon;
                        object.cpe = devices.cpe;
                        innerMapping.values.push(object);
                        headerLoop.push(innerMapping);
                        innerMapping = {title: '', values: []};
                    } else if (device && duplicatedDevice) {
                        headerLoop.forEach(element => {
                            if (element.title == mappedPlatform.platformType) {
                                const object = Object.assign({}, device);
                                object.comingSoon = devices.comingSoon;
                                object.selected = devices.selected;
                                object.cpe = devices.cpe;
                                element.values.push(object);
                            }
                        });
                    }
                });
            }
        });
        this.headerloop = headerLoop;
    }

    getUserBrowserDevice() {
        const userDetails = this.authService.getTrackerLoggedInUser();
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        this.userBrowser = this.deviceService.browser;
        this.userDevice = isMobile ? 'mobile' : (isTablet ? 'tablet' : (isDesktopDevice ? 'desktop' : ''));
        this.userDeviceOS = this.deviceService.os;
        this.userUniqueid = userDetails ? userDetails?.user?.email : '';
    }

    getPlatformTypeText(platformType) {
        switch(platformType && platformType.toLowerCase()) {
            case 'ios': 
                platformType = 'iOS';
                break;
            case 'android': 
                platformType = 'Android';
                break;
            case 'web': 
                platformType = 'Web';
                break;
            case 'macos': 
                platformType = 'macOS';
                break;
            case 'windows': 
                platformType = 'Windows';
                break;
            case 'mobile-site': 
                platformType = 'Mobile Site';
                break;
            case 'watchos': 
                platformType = 'watchOS';
                break;
        }
        return platformType;
    }

    getAllFeatures() {
        this.allFeaturesArray =[];
        this.featureList.forEach(e => {
            this.allFeaturesArray.push(e);
            if (e?.sub_features?.length) this.allFeaturesArray.push(...e?.sub_features);
        });
        return this.allFeaturesArray;
    }
}
