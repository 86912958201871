export const environment = {
    production: false,
    envName: 'staging',
    API_URL: 'https://api-staging-now.engineer.ai',
    STUDIO_API_URL: 'https://api-staging-builder.engineer.ai',
    STUDIO_URL: 'https://staging.engineer.ai',
    BUILDER_PAYMENT_URL: 'https://staging.engineer.ai/instant_prototype?build_card_id={buildCardId}',
    BUILDER_FEATURES_URL: 'https://staging.engineer.ai/#/features',
    USER_COOKIE: 'user_staging',
    GUIDLINE_TOOTIP_COOKIE: 'staging_guidline',
    enableSentry: 'true',
    tracker_user: 'tracker_staging',
    tracker_user_beta: 'tracker_staging_beta',
    API_TRACKER_URL: 'https://staging.api-tracker-uiw.builder.ai',
    API_NOW_URL: 'https://staging.api-tracker-uiw.builder.ai',
    TRACKER_STAGING_URL: 'https://staging2-buildertracker.engineer.ai',
    API_360_TOKEN: 'gSBqlyCZQfDNWWFApHuf',
    API_360: 'https://api-staging-pmdashboard.engineer.ai/',
    redirectionTracker: 'https://staging-buildertracker.engineer.ai',
    bucket_document: 'https://builder-now-staging.s3.amazonaws.com',
    base_Sketch: 'https://cmt-staging.builder.ai',
    base_Sketch_bucket: 'https://staging-cmt-assets.s3.amazonaws.com/',
    API_360_KEY_NOTES: 'lMcslDMHpHidsFaQRjbA',
    white_board_base_url: 'https://www.whiteboard.team',
    whiteBoardClientId:'0a5513c6d360b46ec7c45aa045642f4c',
    whiteBoardClientSecret: '02466ba44b99bd7404b5d574f92bff48',
    nowUrl: 'https://staging-now.engineer.ai',
    HIVE_URL: 'https://staging-developer.builder.ai/creator/courses',
    X_FIGMA_TOKEN: 'figd_uW-zKczVJPfOH1uCCJ3Eh3MaeSCD0zP5cHtoCML2',
    FIGMA_URL: 'https://www.figma.com',
    apeiros: 'https://staging-whiteboard.builder.ai',
    cmtFeedBackLoop: 'https://cmt-staging.builder.ai/api/v2/',
    cmtToken: '06793d51-6416-4d97-a23b-2a727ecebec6',
    nowToken: '7aMA_LLsLSVcA7awYyUc',
    trackerSocekt: 'https://staging.api.tracker.engineer.ai/api/v1/sockets/auth',
    socketUrl:'wss://staging.centrifugo.tracker.engineer.ai/connection/websocket',
    cmtUserName: 'uiw_now_staging',
    API_360_BCHT:'UheeeUKUKrwLzKZuKjux',
    bchat_env :'staging',
    authenticationToken:'YmNoYXRfbG9jYWw6cGFzc3dvcmQ',
    apiEndpointBchat:'staging-chat.builder.ai',
};
