import { Injectable } from '@angular/core';
import { RectangleComponent } from './rectangle-component/rectangle-component';
import {DataCommService} from '../../../main/shared/services/data-comm.service';
import { Rectangle } from './models/shape';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ManualHotspotService {
  public isNewHotspotDraw = true;

  private shapesComponents: RectangleComponent[] = [];
  public getManualHotstpotDataArray = [];

  public selectedComponent: RectangleComponent;
  public showHideTooltip = false;
  public isDragging = false;
  public isDrawing = false;
  public isResizing = false;
  public selectedItemId;
  public selectedClickableItem;
  public tooltipPos = {
    x: null,
    y: null
  };
  public showHideToolTip = false;
  public showContextMenu = false;
  editOnHotspotDblClick: Subject<any> = new Subject();
  isShowHotspotTooltip: boolean = false;
  hoverShape: any = {};

  constructor(private dataCommService: DataCommService) {
  }

  getRectangleComponents(): RectangleComponent[] {
    return this.shapesComponents;
  }

  removeSelectedRectangleComponent(component: RectangleComponent): void {
    const comps = this.getRectangleComponents();
    const index = comps.indexOf(component, 0);
    if (index > -1) {
      comps.splice(index, 1);
    }
  }

  removeAllRectangleComponents(): void {
    this.shapesComponents = [];
  }

  setRectangleComponent(component: RectangleComponent): void {
    this.selectedComponent = component;
    this.shapesComponents.push(component);
  }

  getRectangleComponent(): RectangleComponent {
    return this.selectedComponent;
  }

  findRectangleComponent(name: string): RectangleComponent {
    this.selectedComponent =  this.shapesComponents.find(x => x.shape.shapeProperties.name === name);
    return this.selectedComponent;
  }

  findRectangleComponentMappedWithClickableItem(hotspotId) {
    this.selectedComponent = this.shapesComponents.find(x => x.currentClickableItem.hotspotId === hotspotId);
    return this.selectedComponent;
  }

  findClickableItem(id) {
    this.selectedClickableItem = this.selectedComponent.clickableItems.find(x => x.id === id);
    return this.selectedClickableItem;
  }
  getClickableItem() {
    return this.selectedClickableItem;
  }
  deleteHotspot() {
    const comps = this.getRectangleComponents();
    const index = comps.indexOf(this.selectedComponent, 0);
    if (index > -1) {
      comps.splice(index, 1);
      this.dataCommService.trackSaveEvents('BN_Hotspot Deleted');
    }
  }

  resetManualHotspotFlags() {
    this.isDragging = false;
    this.isDrawing = false;
    this.isResizing = false;
    this.showHideTooltip = false;
  }

  /**
   * @description: Deselect any selected
   * manual hotspot
   */
  deSelectComponents() {
    const shapes = this.getRectangleComponents();
    shapes.forEach((elm, index) => {
      shapes[index].isSelected = false;
      shapes[index].hotspotEditable = false;
    });
  }

  getClickaleItemOnHotpotDblClick(): Observable<any>{
    return this.editOnHotspotDblClick.asObservable();
  }

  setClickaleItemOnHotpotDblClick(rectangle: Rectangle){
    const rectangles = this.getRectangleComponents();
    const ind = rectangles.findIndex(rect => rect.shape.shapeProperties.name === rectangle.shapeProperties.name);
    this.editOnHotspotDblClick.next(rectangles[ind]);
  }
}
