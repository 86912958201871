import { ErrorHandler, Injectable } from '@angular/core';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor() {
     // This is intentional
     }
    handleError() {
        // This is intentional
    }
}

