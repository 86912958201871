import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [
  { path: "", redirectTo: 'uiworkspace', pathMatch: "full" },
  { path: 'uiworkspace', loadChildren: () => import('./main/uiw-main/uiw-main.module').then(m => m.UiwMainModule) }
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes, {});



