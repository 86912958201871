import { StylebrandingModel } from '../models/style-branding.model.';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CookieService } from '@core/cookie.service';
import { BaseService } from '@core/base.service';
import { Constants } from '@shared/services/constants';
import { ToasterService } from '@core/toaster.service';
import { AuthService } from '@core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DataService extends BaseService {
  constructor(
    public cookieService: CookieService,
    public router: Router,
    public toaster: ToasterService,
    private http: HttpClient,
    private authService: AuthService) {
    super(router, toaster);
  }

  getBuilderFeatursUrl() {
    return this.builderFeaturesUrl;
  }

  getBuilderPaymentUrl(buildcardId: string) {
    const url = `${this.builderPaymentUrl}`;
    return url.replace('{buildCardId}', buildcardId);
  }


  getDataByBuildcardId(buildcardId: string, platform: string): Observable<any[]> {

    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.previewData}`;
    url = url.replace('{build_card_id}', buildcardId);
    url = url.replace('{platform}', platform);
    if (this.authService.cutsotmPrototypeVersion !== null) {
      url = url.replace('{prototypeType}', Constants.prototypeType.customType);
    } else {
      url = url.replace('{prototypeType}', Constants.prototypeType.instantType);
    }
    return this.http.get<any[]>(`${url}`);
  }

  launchScreen(buildcardId, heroId, platform, nowBuildCardId, version): Observable<any> {
    let url = `${this.baseUrl}${'/' + Constants.apiEndPoints.launchScreen}`;
    url = url.replace('{build_card_id}', nowBuildCardId);
    const data = {
      platform: platform,
      build_card_feature_id: heroId,
      build_card_id: buildcardId,
      version: version
    };
    return this.http.put<any>(url, data);
  }

  updateHotspots(hotspotsData: any) {
    const url = `${this.baseUrl}${Constants.apiEndPoints.updateHotspots}`;
    return this.http.put(url, hotspotsData);
  }

  getSideKickData(frameId, buildCardHeroImageId): Observable<any[]> {
    let url = `${this.baseUrl}${Constants.apiEndPoints.sideKickData}`;
    url = url.replace('{frame_id}', frameId);
    url = url.replace('{build_card_hero_image_id}', buildCardHeroImageId);
    return this.http.get<any[]>(`${url}`);
  }

  updateSideKicks(hotspotsData: any, nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateSideKick}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    return this.http.put(url, hotspotsData);
  }

  sendMailSMS(shareDetails: any, nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.sendMailSMS}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    return this.http.post(url, shareDetails);
  }

  deleteScreen(featureId, platform, nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.deleteScreen}`;
    url = url.replace('{feature_id}', featureId);
    url = url + `?build_card_id=${nowBuilCardId}&platform=${platform}`;
    return this.http.delete(url);
  }

  updateScreen(updateScreenData: any, featureId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateScreen}`;
    url = url.replace('{feature_id}', featureId);
    return this.http.put(url, updateScreenData);
  }

  getSuggestions(featureId, heroFeatureId, nowBuilCardId, platform) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.suggestions}`;
    url = url.replace('{feature_id}', featureId);
    url = url.replace('{hero_feature_id}', heroFeatureId);
    url = url.replace('{build_card_id}', nowBuilCardId);
    url = url.replace('{platform}', platform);
    return this.http.get<any[]>(`${url}`);
  }

  updateIndustry(updateIndustryData: any, buildcardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.setIndustryType}`;
    url = url.replace('{build_card_id}', buildcardId);
    return this.http.put(url, updateIndustryData);
  }

  uploadImageFeature(formData) {
    const url = `${this.baseUrl}${Constants.apiEndPoints.uploadImageFeature}`;
    return this.http.post(url, formData);
  }

  getIconsList(nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getIconsList}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    return this.http.get<any[]>(`${url}`);
  }
  uploadIcons(formData, nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getIconsList}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    return this.http.post(url, formData);
  }
  saveIconHotspot(hotspotArrObj, featureId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.saveIconHotspot}`;
    url = url.replace('{feature_id}', featureId);
    return this.http.post(url, hotspotArrObj);
  }

  saveCustomSidekick(hotspotArrObj, frameId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.addCustomSidekick}`;
    url = url.replace('{feature_id}', frameId);
    return this.http.post(url, hotspotArrObj);
  }

  addCustomHotspot(hotspotArrObj, frameId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.addCustomHotspot}`;
    url = url.replace('{feature_id}', frameId);
    return this.http.post(url, hotspotArrObj);
  }
  getConfigData(): Observable<any[]> {
    const url = `${this.baseUrl}${Constants.apiEndPoints.industryTypeList}`;
    return this.http.get<any[]>(`${url}`);
  }
  statusCheck(nowBuildId, featureSetUniqueCode, platform) {
    featureSetUniqueCode = {
      feature_set_uniq_code: featureSetUniqueCode
    };
    let url = `${this.baseUrl}${Constants.apiEndPoints.checkStatus}`;
    url = url.replace('{now_build_id}', nowBuildId);
    url = url.replace('{platform}', platform);

    return this.http.post(url, featureSetUniqueCode);
  }
  setPassword(nowBuildId, data) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.setPassword}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.put(url, data);
  }

  checkPassword(nowBuildId, data) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.checkPassword}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.post(url, data);
  }

  getPassword(nowBuildId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getPassword}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.get(url);
  }
  actionTypeList(nowBuildId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.industryTypeList}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.get(url);
  }
  updateProjectName(updatedName, nowBuildId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateProjectName}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.put(url, updatedName);
  }
  updateFeatureName(data, featureId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateScreenName}`;
    url = url.replace('{feature_id}', featureId);
    return this.http.put(url, data);
  }
  setCPEPrototype(data, nowBuildId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.setCPEPrototype}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.put(url, data);
  }
  customPrototypeAmount(nowBuildId) {
    let url = `${this.studioBaseUrl}${Constants.apiEndPoints.customPrototype}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.get(url);
  }
  syncData(data, nowBuildId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.syncData}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.put(url, data);
  }
  approveRejectVersion(nowBuildCardId, data) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.approveFeatureVersion}`;
    url = url.replace('{build_card_id}', nowBuildCardId);
    return this.http.put(url, { build_card_approval_details: data });
  }

  postRejectionComment(nowBuildCardId, data) {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.postRejectionComment}`;
    url = url.replace('{build_card_id}', nowBuildCardId);
    return this.http.post(url, { build_card_approval_details: data });
  }

  getBranding(buildcardId: string): Observable<StylebrandingModel> {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.branding}`;
    url = url.replace('{build_card_id}', buildcardId);
    return this.http.get<any>(`${url}`);
  }
  dummyLogo(data, buildcardId: string, brandingId: string): Observable<any> {
    let endPoint = Constants.apiEndPoints.updateBranding.replace('{build_card_id}', buildcardId);
    endPoint = `${this.baseUrl}${endPoint.replace('{now_branding_id}', brandingId)}`;
    return this.http.put(endPoint, data);
  }
  delete(attachment_id: string, buildcardId: string) {
    let endPoint = Constants.apiEndPoints.deleteBranding.replace('{build_card_id}', buildcardId);
    endPoint = `${this.baseUrl}${endPoint.replace('{delete_attachment_id}', attachment_id)}`;
    return this.http.delete(endPoint);
  }

  updateBranding(logo, font, color, icon, illustration, other, buildcardId: string, brandingId: string): Observable<any> {
    let endPoint = Constants.apiEndPoints.updateBranding.replace('{build_card_id}', buildcardId);
    endPoint = `${this.baseUrl}${endPoint.replace('{now_branding_id}', brandingId)}`;

    const updateLogo = this.http.put(endPoint, logo);
    const updateFont = this.http.put(endPoint, font);
    const updateColor = this.http.put(endPoint, color);
    const updateIcon = this.http.put(endPoint, icon);
    const updateIllustration = this.http.put(endPoint, illustration);
    const updateOther = this.http.put(endPoint, other);
    return forkJoin([updateLogo, updateFont, updateColor, updateIcon, updateIllustration, updateOther]);
  }
  getDashboardFeatureLists(buildcardId: string, platform: string) {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.dashboardFeatureList}`;
    url = url.replace('{build_card_id}', buildcardId);
    url = url.replace('{platform}', platform);
    return this.http.get<any>(`${url}`);
  }
  addFeatures(nowBuildCardId, data) {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.addFeatures}`;
    url = url.replace('{build_card_id}', nowBuildCardId);
    return this.http.post(url, data);
  }
  deleteFeatures(nowBuildCardId, data) {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.deleteFeatures}`;
    url = url.replace('{build_card_id}', nowBuildCardId);
    url = url.replace('{feature_ids}', data);
    return this.http.put(url, data);
  }

  uploadLogo(formData, nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.logoApi}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    return this.http.put(url, formData);
  }
}
