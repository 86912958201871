import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '@env/environment';
import { ToasterService } from '@core/toaster.service';
import { Constants} from '@shared/services/constants';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public baseUrl;
  public builderPaymentUrl;
  public builderFeaturesUrl;
  public studioBaseUrl;
  public studioUrl;
  public trackerStagingUrl;
  public builder360Url;
  public redirectionTracker;
  public bucketdocument;
  public baseSketch;
  public baseSketchUrl;
  public whiteBoardBaseUrl;
  public cmtFeebBackUrl;
  public nowBaseUrl;
  public trackerSocekt;


  constructor(
    public router: Router,
    public toaster: ToasterService
  ) {
    this.baseUrl = environment.API_TRACKER_URL;
    this.studioBaseUrl = environment.STUDIO_API_URL;
    this.builderPaymentUrl = environment.BUILDER_PAYMENT_URL;
    this.builderFeaturesUrl = environment.BUILDER_FEATURES_URL;
    this.studioUrl = environment.STUDIO_URL;
    this.trackerStagingUrl = environment.TRACKER_STAGING_URL;
    this.builder360Url = environment.API_360;
    this.redirectionTracker = environment.redirectionTracker;
    this.bucketdocument = environment.bucket_document;
    this.baseSketch = environment.base_Sketch;
    this.baseSketchUrl = environment.base_Sketch_bucket;
    this.whiteBoardBaseUrl = environment.white_board_base_url;
    this.cmtFeebBackUrl = environment.cmtFeedBackLoop;
    this.nowBaseUrl = environment.API_URL;
    this.trackerSocekt = environment.trackerSocekt;
   }

  genericSuccess(request: any, response: any) {
    if (response.body.message) {
      this.toaster.success(response.body.message);
    } else {
      if (request.method.toLocaleLowerCase() === 'delete') {
        this.toaster.success(Constants.toasterMessage.DELETE);
      } else {
        this.toaster.success(Constants.toasterMessage.SUCCESS);
      }
    }
    return response;
  }


  genericError(error: any) {
    let sError = '';
    try {
      sError = error.error.message || (error.error.length > 0 && error.error[0]); 
    } catch (e) {
      sError = Constants.toasterMessage.ERROR;
    }
    if (!sError) {
      sError = Constants.toasterMessage.ERROR;
    }
    if(error?.error?.errors && error?.error?.errors[0] !== 'No comments available.'){
      this.toaster.error(sError);
    }
    if(error?.error[0] == 'Owned by Please assign owner.'){
        this.toaster.error(sError);
    }
    return throwError(sError);
  }

  validationError() {
    let sError = 'Hours Cannot Be Less Than Original Estimate';
    this.toaster.error(sError);
  }

  authTokenErrorHndlr(error: any,status) {
    if(status == 404){
      return throwError(Constants.toasterMessage.Redirect);
    }
    return throwError(Constants.toasterMessage.INVALID_TOKEN);
  }

  internalServerErr() {
    this.toaster.error(Constants.toasterMessage.INTERNAL_SERVER_ERR, '', {enableHtml: true, toastClass: 'widthIncrease ngx-toastr'});
  }

}
