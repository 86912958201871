import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
 
import { HighlightDirective } from './directives/highlight.directive';
import { FeatureFrameComponent } from './components/feature-frame/feature-frame.component';
import { MobOtherOptionsComponent } from './components/feature-frame/mob-other-options/mob-other-options.component';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG,
   PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { SideToolbarComponent } from './components/side-toolbar/side-toolbar.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { SpinnerLoaderComponent } from './components/spinner-loader/spinner-loader.component';
import { TabsModule } from './modules/tabs/tabs.module';
import { OverlayWithContentComponent } from './components/overlay-with-content/overlay-with-content.component';
import { HeroFrameListingComponent } from './components/hero-frame-listing/hero-frame-listing.component';
import { FeatureFrameEditableComponent } from './components/feature-frame-editable/feature-frame-editable.component';
import { ManualShareService } from './components/manual-hotspot/manual.shared.service';
import { ManualHotspotService } from './components/manual-hotspot/manual-hotspot.service';
import { ManualHotspotDirective } from './components/manual-hotspot/manual-hotspot.directive';
import { ManualHotspotComponent } from './components/manual-hotspot/manual-hotspot.component';
import { RectangleComponent } from './components/manual-hotspot/rectangle-component/rectangle-component';
import { TabComponent } from './modules/tabs/tab/tab.component';
import { TabsComponent } from './modules/tabs/tabs.component';
import { OverlayWithContentService } from './components/overlay-with-content/overlay-with-content.service';
import { ShareModule } from '@ngx-share/core';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { NgxFileDropModule } from 'ngx-file-drop';
import { IndustryTypeComponent } from './components/industry-type/industry-type.component';
import { BindActionDirective } from './directives/bind-action.directive';
import { CustomPrototypePaymentComponent } from './components/custom-prototype-payment/custom-prototype-payment.component';
import { FeatureActionComponent } from './components/feature-action/feature-action.component';
import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { SyncDataComponent } from './components/sync-data/sync-data.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {UiwHeaderComponent} from "@shared/components/uiw-header/uiw-header.component";
import {UiwSidenavComponent} from "@shared/components/uiw-sidenav/uiw-sidenav.component";
import {UiwRightPanelComponent} from "../uiw-main/uiw-right-panel/uiw-right-panel.component";
import {NotificationComponent} from "../uiw-main/notification/notification.component";
import {UiwStoriesComponent} from "../uiw-main/uiw-right-panel/uiw-stories/uiw-stories.component";
import {UiwActivityComponent} from "../uiw-main/uiw-right-panel/uiw-activity/uiw-activity.component";
import {UiwCommentPanelComponent} from "../uiw-main/uiw-right-panel/uiw-comment-panel/uiw-comment-panel.component";
import { CountdownModule } from 'ngx-countdown';
import {UiwFeatureInfoComponent} from "../main/uiw-main/uiw-feature-info/uiw-feature-info.component";
import {SlideLoaderComponent} from '../uiw-main/uiw-right-panel/slide-loader/slide-loader.component';
import {UiwSketchUploadComponent} from '../main/uiw-main/uiw-sketch-upload/uiw-sketch-upload.component';
import {StoryOperationsComponent} from "../uiw-main/story-operations/story-operations.component";
import {UiwStoryDetailComponent} from "../uiw-main/uiw-right-panel/uiw-story-detail/uiw-story-detail.component";
import {UiwVarientComponent} from '../uiw-main/uiw-varient/uiw-varient.component';
import {UiwFeatureInfoWidgetComponent} from "../uiw-main/uiw-feature-info-widget/uiw-feature-info-widget.component";
import {UiwTimerComponent} from "../uiw-main/uiw-timer/uiw-timer.component";
import {FormatTimePipe} from "@shared/pipes/timer.pipe";
import {StoryTagsComponent} from "../uiw-main/story-tags/story-tags.component";
import {UiwUnlinkedComponent} from '../uiw-main/uiw-right-panel/uiw-unlinked/uiw-unlinked.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {UiwUserUploadComponent} from '../uiw-main/uiw-user-upload/uiw-user-upload.component';
import { CommentFilterPipe } from './pipes/comment.pipe';
import { MainCommentFilterPipe } from './pipes/main-comment.pipe';
import {UiwMaintenanceSupportComponent} from '../uiw-main/uiw-maintenance-support/uiw-maintenance-support.component';
import {UiwReleasePopupComponent} from '../uiw-main/uiw-release-popup/uiw-release-popup.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { SketchErrorPopupComponent } from './components/sketch-error-popup/sketch-error-popup.component';
import { UiwFeatureInfoWidetTooltipComponent } from '../uiw-main/uiw-feature-info-widet-tooltip/uiw-feature-info-widet-tooltip.component';
import { UiwFeatureInfoRightComponent } from '../main/uiw-main/uiw-feature-info-right/uiw-feature-info-right.component';
import {UiwAssignStoryComponent} from '../uiw-main/uiw-right-panel/uiw-assign-story/uiw-assign-story.component';
import { SubstorylinkingComponent } from './components/substorylinking/substorylinking.component';
import { DeallocatedToastComponent } from './components/deallocated-toast/deallocated-toast.component';
import { VisualQAPopupComponent } from './components/visual-qa-popup/visual-qa-popup.component';
import { SafePipe } from './pipes/safe.pipe';
import { PromoteDenyScreenComponent } from './components/promote-deny-screen/promote-deny-screen.component';
import { StartStoryConfirmationPopupComponent } from './components/start-story-confirmation-popup/start-story-confirmation-popup.component';
import { FigmaPopupComponent } from './components/figma-popup/figma-popup.component';
import { SummaryInfoPopupComponent } from './components/summary-info-popup/summary-info-popup.component';
import { StoriesCardComponent } from './components/stories-card/stories-card.component';
import { VarientCardComponent } from './components/varient-card/varient-card.component';
import { AttachmentCardComponent } from './components/attachment-card/attachment-card.component';
import { FigmaInfoPopupComponent } from './components/figma-info-popup/figma-info-popup.component';
import { SearchRightPanelPopupComponent } from './components/search-right-panel-popup/search-right-panel-popup.component';
import { HighlightPipe } from './pipes/highlight-pipe';
import { StoryOperationOverlayComponent } from '../uiw-main/story-operation-overlay/story-operation-overlay.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RequestToPromoteScreenComponent } from './components/request-to-promote-screen/request-to-promote-screen.component';
import { DlsMetricsPopupComponent } from './components/dls-metrics-popup/dls-metrics-popup.component';
import { SearchDlsPipe } from './pipes/search-dls-users.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FloatingTimerComponent } from './components/floating-timer/floating-timer.component';
import { SwapDesignModalComponent } from './components/swap-design-modal/swap-design-modal.component';
import { FigmaWarningPopupComponent } from './components/figma-warning-popup/figma-warning-popup.component';
import { CanvasSearchComponent } from './components/canvas-search/canvas-search.component';
import { RequestFigmaAccessPopupComponent } from './components/request-figma-access-popup/request-figma-access-popup.component';
import { UiwJourneyHeaderComponent } from './components/uiw-journey-header/uiw-journey-header.component';
import { JourneyCardComponent } from './components/journey-card/journey-card.component';
import { JourneyDetailsComponent } from './components/journey-details/journey-details.component';
import { SendToCustomerPopupComponent } from './components/send-to-customer-popup/send-to-customer-popup.component';
import { JourneySearchPipe } from './pipes/journey.pipe';
import { NewLoaderComponent } from './components/new-loader/new-loader.component';
import { PlatformsDropdownComponent } from './components/platforms-dropdown/platforms-dropdown.component';
import { UiwJourneyStoriesComponent } from '../uiw-main/uiw-right-panel/uiw-journey-stories/uiw-journey-stories.component';
import { CommentPopupComponent } from './components/comment-popup/comment-popup.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    DragDropModule,
    FormsModule,
    CommonModule,
    RouterModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true
    }),
    PerfectScrollbarModule,
    TabsModule,
    ShareModule,
    FontAwesomeModule,
    ShareButtonsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    CountdownModule,
    MDBBootstrapModule.forRoot(),
    NgxPaginationModule,
    ClipboardModule,
    NgCircleProgressModule.forRoot({
      backgroundColor: "#ffffff",
      backgroundStrokeWidth: 2,
      backgroundPadding: 11,
      radius: 60,
      space: -2,
      toFixed: 0,
      outerStrokeWidth: 4,
      innerStrokeWidth: 4,
      showSubtitle: false,
      showUnits: false,
      showBackground: true,
      clockwise: false,
      showZeroOuterStroke: false
    }),
    AngularMyDatePickerModule
  ],
  declarations: [
      UiwHeaderComponent,
      UiwSidenavComponent,
      UiwRightPanelComponent,
      NotificationComponent,
      UiwStoriesComponent,
      UiwActivityComponent,
      UiwCommentPanelComponent,
    SlideLoaderComponent,
    HighlightDirective,
    FeatureFrameComponent,
    MobOtherOptionsComponent,
    SideToolbarComponent,
    ConfirmationModalComponent,
    ConfirmationModalComponent,
    SpinnerLoaderComponent,
    OverlayWithContentComponent,
    HeroFrameListingComponent,
    SpinnerLoaderComponent,
    FeatureFrameEditableComponent,
    ManualHotspotComponent,
    ManualHotspotDirective,
    RectangleComponent,
    IndustryTypeComponent,
    BindActionDirective,
    CustomPrototypePaymentComponent,
    FeatureActionComponent,
    ModalPopupComponent,
    SyncDataComponent,
    UiwFeatureInfoComponent,
    UiwFeatureInfoRightComponent,
    UiwSketchUploadComponent,
    StoryOperationsComponent,
    UiwStoryDetailComponent,
    UiwVarientComponent,
      StoryOperationsComponent,
      UiwStoryDetailComponent,
    UiwFeatureInfoWidgetComponent,
    UiwFeatureInfoWidetTooltipComponent,
      UiwTimerComponent,
      FormatTimePipe,
      StoryTagsComponent,
      UiwUnlinkedComponent,
    UiwUserUploadComponent,
    CommentFilterPipe,
    MainCommentFilterPipe,
    UiwMaintenanceSupportComponent,
    UiwReleasePopupComponent,
    ConfirmationPopupComponent,
    SketchErrorPopupComponent,
    UiwAssignStoryComponent,
    SubstorylinkingComponent,
    DeallocatedToastComponent,
    VisualQAPopupComponent,
    SafePipe,
    SearchDlsPipe,
    PromoteDenyScreenComponent,
    StartStoryConfirmationPopupComponent,
    SummaryInfoPopupComponent,
    StoriesCardComponent,
    VarientCardComponent,
    AttachmentCardComponent,
    FigmaPopupComponent,
    SummaryInfoPopupComponent,
    FigmaInfoPopupComponent,
    SearchRightPanelPopupComponent,
    HighlightPipe,
    StoryOperationOverlayComponent,
    RequestToPromoteScreenComponent,
    DlsMetricsPopupComponent,
    FloatingTimerComponent,
    SwapDesignModalComponent,
    FigmaWarningPopupComponent,
    CanvasSearchComponent,
    RequestFigmaAccessPopupComponent,
    UiwJourneyHeaderComponent,
    JourneyCardComponent,
    JourneyDetailsComponent,
    SendToCustomerPopupComponent,
    JourneySearchPipe,
    NewLoaderComponent,
    PlatformsDropdownComponent,
    UiwJourneyStoriesComponent,
    CommentPopupComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ManualShareService,
    ManualHotspotService,
    OverlayWithContentService
  ],
    exports: [
      CountdownModule,
        UiwHeaderComponent,
        UiwSidenavComponent,
        UiwRightPanelComponent,
        NotificationComponent,
        UiwStoriesComponent,
        UiwActivityComponent,
        SlideLoaderComponent,
        UiwCommentPanelComponent,
        HighlightDirective,
        FeatureFrameComponent,
        MobOtherOptionsComponent,
        SideToolbarComponent,
        ConfirmationModalComponent,
        ConfirmationModalComponent,
        SpinnerLoaderComponent,
        OverlayWithContentComponent,
        HeroFrameListingComponent,
        SpinnerLoaderComponent,
        FeatureFrameEditableComponent,
        ManualHotspotComponent,
        ManualHotspotDirective,
        RectangleComponent,
        TabComponent,
        TabsComponent,
        PerfectScrollbarComponent,
        IndustryTypeComponent,
        BindActionDirective,
        CustomPrototypePaymentComponent,
        FeatureActionComponent,
        ModalPopupComponent,
        SyncDataComponent,
        MDBBootstrapModule,
        UiwFeatureInfoComponent,
        UiwFeatureInfoRightComponent,
        UiwSketchUploadComponent,
        StoryOperationsComponent,
      UiwStoryDetailComponent,
        UiwFeatureInfoWidgetComponent,
        UiwFeatureInfoWidetTooltipComponent,
        UiwTimerComponent,
        FormatTimePipe,
      StoryTagsComponent,
        UiwStoryDetailComponent,
        UiwVarientComponent,
      UiwUnlinkedComponent,
      UiwUserUploadComponent,
      CommentFilterPipe,
      MainCommentFilterPipe,
      UiwMaintenanceSupportComponent,
      UiwReleasePopupComponent,
      SketchErrorPopupComponent,
      UiwReleasePopupComponent,
      ConfirmationPopupComponent,
      SketchErrorPopupComponent,
      SubstorylinkingComponent,
      UiwAssignStoryComponent,
      DeallocatedToastComponent,
      VisualQAPopupComponent,
      SafePipe,
      PromoteDenyScreenComponent,
      StartStoryConfirmationPopupComponent,
      NgxPaginationModule,
      StartStoryConfirmationPopupComponent,
      FigmaPopupComponent,
      SummaryInfoPopupComponent,
      SearchRightPanelPopupComponent,
      HighlightPipe,
      StoryOperationOverlayComponent,
      RequestToPromoteScreenComponent,
      DlsMetricsPopupComponent,
      FloatingTimerComponent,
      SwapDesignModalComponent,
      FigmaWarningPopupComponent,
      CanvasSearchComponent,
      RequestFigmaAccessPopupComponent,
      UiwJourneyHeaderComponent,
      JourneyCardComponent,
      JourneyDetailsComponent,
      SendToCustomerPopupComponent,
      ClipboardModule,
      JourneySearchPipe,
      NewLoaderComponent,
      PlatformsDropdownComponent,
      UiwJourneyStoriesComponent,
      CommentPopupComponent
    ],
  entryComponents: [
    RectangleComponent
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fab);
  }
}

